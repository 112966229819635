$timeline-color: #E81123;

.timeline {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 20px;

  &::before,
  &::after {
    content: "";
    display: block;
    width: 60px;
    height: 10px;
    border-right: 1px dotted $timeline-color;
  }

  &-year {
    display: flex;
    align-items: flex-start;

    & ul {
      margin: 0;
      padding: 0;
      list-style: none;
      padding-left: 16px;
      border-left: 1px solid $timeline-color;
    }

    & > span {
      position: sticky;
      top: 0;
      margin-bottom: 24px;
      font-family: "Open Sans", sans-serif;
      width: 60px;
      flex-shrink: 0;
      white-space: nowrap;

      &::after {
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        background-color: #000;
        border: 2px solid red;
        border-radius: 8px;
        position: absolute;
        top: 50%;
        transform: translateY(-6px);
        right: -8px;
      }
    }
  }

  &-event {
    display: flex;
    flex-direction: column;

    &-date {
      font-weight: bold;
      margin-bottom: 8px;
    }

    &-content {
      font-family: "Open Sans", sans-serif;
      margin-bottom: 24px;
      font-size: 18px;
      opacity: 0.8;
      max-width: 500px;
    }
  }
}
