.contancts {
  display: flex;
  flex-direction: column;
  & a,
  & img {
    width: 36px;
  }

  & a {
    margin-bottom: 24px;
  }
}
