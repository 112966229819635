nav {
  margin-bottom: 64px;

  & ul {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  & li {
    display: inline-block;
    margin-right: 64px;

    @media (max-width: 768px) {
      margin-right: 32px;
    }

    &:last-child {
      margin-right: 0;
    }

    & > a {
      color: #fff;
      transition: 0.25s;
      text-decoration: none;
      padding: 5px 0;
      &:hover {
        color: #E81123;
        transition: 0.25s;
      }
    }
  }
}
