.initial {
  display: flex;
  flex-direction: column;
  background-image: url(../Assets/Images/intro_bg.webp),
    url(../Assets/Images/intro_bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-color: #000 !important;
  background-size: contain;

  @media (orientation: portrait) {
    background-position: center 65%;
  }

  @media (max-width: 360px) and (orientation: portrait) {
    background-image: none;
  }

  @media (max-width: 768px) and (orientation: portrait) {
    background-position: center 80%;
  }

  @media (max-width: 1024px) and (orientation: landscape) {
    background-image: none;
  }

  &-title,
  &-title > a {
    text-decoration: none;
    color: #fff;
  }
  &-paragraph {
    font-size: 42px;
    line-height: 48px;
    margin-top: 0;
    & > span {
      font-weight: 100;
    }

    @media (min-width: 400px) and (max-width: 480px) and (orientation: portrait) {
      font-size: 32px;
      line-height: 36px;
      text-align: left;
    }

    @media (max-width: 400px) and (orientation: portrait) {
      font-size: 25px;
      line-height: 32px;
      text-align: left;
    }

    
  }

  & .scroll-me {
    margin-top: auto;
  }
}
